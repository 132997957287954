<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Direcciones
            </div>

            <v-form :disabled="loading">
                <v-row>
                    <v-col cols="12" sm="7" md="5" lg="5" xl="3">
                        <v-text-field
                            class="mb-n7"
                            outlined
                            dense
                            text
                            v-model="filtros.DirNom"
                            label="Nombre"
                            @change="loadData"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>

            <v-btn
            to="/direccion-nueva"
            color="primary"
            class="mt-5 mb-3">
            Nueva
            </v-btn>

            <v-data-table
                :headers="headers"
                :items="dataRows"
                :options.sync="options"
                :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
                :server-items-length="totalDataRows"
                :loading="loading"
                @click:row="onClickRowEditarDireccion"
                style="overflow: scroll"
                mobile-breakpoint="0"
                class="elevation-1"
                dense
                >
            </v-data-table>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default ({
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            loading: true,
            totalDataRows: 0,
            dataRows: [],
            options: {itemsPerPage: 15},
            headers: [
                { text: 'Código', width: 50, sortable: false, value: 'DirCod' },
                { text: 'Nombre', width:300, sortable: false, value: 'DirNom' },
                { text: 'Dirección', width:300, sortable: false, value: 'DirDir' },
                { text: 'Código postal', width: 110, sortable: false, value: 'DirPosCod' },
                { text: 'Localidad', width: 100, sortable: false, value: 'DirLocNom' },
                { text: 'Provincia', width: 100, sortable: false, value: 'DirProvNom' },
                { text: 'País', width: 100, sortable: false, value: 'DirPaisNom' },
            ],
            dtOptionsLoaded: false,
            filtros: {
                DirNom: ''
            }
        }
    },
    watch: {
      options: {
        handler () {
          // Evitamos la llamada a la API en la primera carga de las options
          if(!this.dtOptionsLoaded){
            this.dtOptionsLoaded = true;
            return;
          }
          this.loadData();
        },
        deep: true
      },
      // filtros: {
      //   handler () {
      //     this.loadData();
      //   },
      //   deep: true
      // },
    },
    mounted() {
      this.loadData();
    },
    methods: {
      getDataFromApi() {
        this.loading = true;

        var NumRegsPag = this.options.itemsPerPage;
        var NumPag = this.options.page;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_DATA_LIST_PAGINATED',
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            DirNom: this.filtros.DirNom,
            EmpId: this.empId
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/dir", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.loading = false
                resolve({
                items,
                total,
              })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert("Error al cargar las direcciones");
            }
          });
        })
      },
      loadData(){
        this.getDataFromApi()
        .then(data => {
          this.dataRows = data.items
          this.totalDataRows = data.total
        });
      },
      onClickRowEditarDireccion(item){
        this.$router.push('/direccion-editar/' + item.DirId);
      }
    } 
})
</script>
